/**
 * External Dependencies
 */

import 'jquery';

$(document).ready(() => {
  $('.burger-menu').click(function() {
    $(this)
      .find('.nav-drop')
      .toggleClass('show');
  });
});
